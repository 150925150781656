import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab } from "@material-ui/core"
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

import SubmitScreen from "./SubmitScreen";
import FullScreen from "./FullScreen";
import Flashpoint from "./Flashpoint";

import { getFlashpointUnit, getScore, logEvent, voteResponse, getBestPractice, completeFlashpoint } from "../redux_actions";
import AlternativeResponses from "./alternativeResponses";
import CheckList from './checklist';
import Quiz from './quiz'
import Points from './points.js'
import store from "../../../Redux_Store";

export default ({ history, app, auth }) => {
    const [params, setParams] = useState({})

    // useEffect(() => {
    //     for (const p of location.search.replace('?', '').split('&')) {
    //         const q = p.split("=")
    //         setParams(oldParam => { return { ...oldParam, [q[0]]: q[1] } })
    //     }
    // }, [location])

    const { unitId } = useParams()
    const { user_token, user_uid } = useSelector(state => state.auth);

    const [unit, setUnit] = useState(null);
    const [showFlashpoint, setShowFlashpoint] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [alternateResponse, setAlternateResponse] = useState(false);
    const [alternateResponseData, setAlternateResponseData] = useState([]);
    const [score, setScore] = useState({});
    const [close, setClose] = useState(false);

    // useEffect(() => {
    //     setShowFlashpoint(params["flashpoint"] === "true")
    //     setAlternateResponse(params["response"] === "true")
    //     setSubmit(params["submit"] === "true")
    // }, [params])

    useEffect(() => {
        store.dispatch({ type: 'RESET_FP_VALUES' })
        store.dispatch({ type: "SELECTED_FP_UNIT_LOADING" })
        getFlashpointUnit(user_token, unitId, user_uid).then(async (res) => {
            document.body.style.backgroundColor = res?.color;
            await store.dispatch({ type: "SELECTED_FP_UNIT", payload: res })
            store.dispatch({ type: "ALTERNATE_RESPONSE_LOADING" })
            getBestPractice(user_token, unitId, "local_area", user_uid, 1).then(async res => {
                await store.dispatch({ type: "ALTERNATE_RESPONSE", payload: res })
                setAlternateResponseData(res);
            })
            setUnit(res);
        });

        if (unit) document.body.style.backgroundColor = unit?.color;

        unit && logEvent(user_token, user_uid, 'FLASHPOINT ACCESS', { flashpointId: unitId, slideId: unit?.content[0]?.id })
        return () => {

        }
    }, [])

    useEffect(() => {
        submit && completeFlashpoint(user_token, unitId, user_uid, "Completed", false).then((res) => {
            store.dispatch({ type: "FP_SCORE", payload: res })
            setScore(res)
        })
        return () => {

        }
    }, [submit])




    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const getFlashpointContent = (content) => {
    //     if (content?.type == "img")
    //         return <img className='d-block mx-auto h-100' src={unit?.content[0]?.src} />
    //     else if (content?.type == "checklist")
    //         return <CheckList data={content} content={content?.items} />
    //     else if (content?.type == "quiz")
    //         return <Quiz content={content?.question} />
    //     else if (content?.type == "feedback")
    //         return <>
    //             <AlternativeResponses />
    //             {/* <div style={{ position: 'relative', width: '100%' }}><Slider content={content?.slider} /></div> */}
    //             {/* <div className='font-tablet-gothic font-weight-bold'>Feedback</div> */}
    //             {/* <Feedback content={content} /> */}
    //             {/* <Quiz content={content?.quiz} /> */}
    //         </>
    //     else if (content?.type == "points")
    //         return <Points content={content} next={() => {
    //             // setAlternateResponse(true);
    //             window.location.search += "&response=true"
    //         }} alternateResponseDataLength={alternateResponseData?.length} />
    //     else if (content?.type == "AlternateResponse")
    //         return <AlternativeResponses />
    // }


    if (submit)
        return <Redirect to={`/flashpoints/unit/${unitId}/submit`} />

    // <SubmitScreen
    //     user_token={user_token}
    //     unitId={unitId}
    //     user_uid={user_uid}
    //     score={score}
    //     unit={unit}
    // />
    else if (showFlashpoint)
        // history.push(`/flashpoints/unit/${unitId}/flashpoint`, {
        //     id: '123'
        // })
        return <Redirect push to={{
            pathname: `/flashpoints/unit/${unitId}/flashpoint`,
            // state: {
            //     showFlashpoint,
            //     submit,
            //     app, auth
            // }
        }} />

    // return <Flashpoint
    //     flashPointRootRef={flashPointRootRef}
    //     unit={unit}
    //     alternateResponse={alternateResponse}
    //     showFlashpoint={showFlashpoint}
    //     submit={submit}
    //     showCloseDialog={showCloseDialog}
    //     setClose={setClose}
    //     scrollBottom={scrollBottom}
    //     alternateResponseData={alternateResponseData}
    //     close={close}
    //     unitId={unitId} />
    else return <FullScreen
        unit={app?.fpSelectedUnit}
        setShowFlashpoint={() => setShowFlashpoint(true)}
        history={history}
    />
    // return <div className='text-center' style={{ minHeight: '100%', backgroundColor: '#FFF8EC' }}>
    //     <div className='font-weight-bold fp-unit-completed large-text'>
    //         {score?.alreadyCompleted ? <>You are already completed <br />the flashpoint!</> : <>Congratulations! <br />You have earned</>}
    //     </div>
    //     <div className='fp-score'>
    //         <div style={{ fontSize: '4rem' }}>{score?.score && Object.values(score?.score).reduce((total, item) => total + item, 0)}</div>
    //         <div style={{ fontSize: '0.8rem' }}>Points</div>
    //     </div>
    //     <div className='font-style-italic'>
    //         {score?.score && Object.entries(score?.score).map(s => {
    //             return <div key={score} className='small-text'> {`${s[0]}-${s[1]}`} Points </div>
    //         })}
    //         {console.log(score, unit)}
    //     </div>

    //     <div className='fp-score-unit-vote'>
    //         <div>Was this useful?</div>
    //         <div style={{ marginTop: '5%' }}>
    //             {
    //                 fpVote === '' && <><ThumbUpOutlined style={{ margin: '0% 7%' }} onClick={() => { voteResponse(user_token, unitId, undefined, user_uid, 1); setFpVote('up') }} />
    //                     <ThumbDownOutlined onClick={() => { voteResponse(user_token, unitId, undefined, user_uid, -1); setFpVote('down') }} /></>
    //             }
    //             {
    //                 fpVote === 'up' && <><ThumbUp style={{ margin: '0% 7%' }} />
    //                     <ThumbDownOutlined onClick={() => { voteResponse(user_token, unitId, undefined, user_uid, -1); setFpVote('down') }} /></>

    //             }
    //             {
    //                 fpVote === 'down' && <><ThumbUpOutlined style={{ margin: '0% 7%' }} onClick={() => { voteResponse(user_token, unitId, undefined, user_uid, 1); setFpVote('up') }} />
    //                     <ThumbDown /></>
    //             }
    //         </div>
    //     </div>
    //     <Link className='text-decoration-none' to='/flashpoints/dashboard'>
    //         <Button className="fp-unit-button">
    //             <span className='text-center' style={{ width: '75%' }}>Go to Dashboard </span>
    //             <span className='text-center' style={{ width: '25%' }}>&nbsp; &nbsp; &gt;</span>
    //         </Button>
    //     </Link>
    //     {/* <WhatsappShareButton className='fp-score-share-button' title='Hey! Checkout this cool flashpoint!' separator=':-' url={window.location.href}>
    //             <span style={{ width: '75%' }}>Share with other associates </span>
    //             <span className='share'>&nbsp; &nbsp; <Share /></span>
    //         </WhatsappShareButton> */}


    // </div>
    // else if (showFlashpoint) {
    //     // const isLast = (activeStep === unit?.content?.length - 1)
    //     return <div ref={flashPointRootRef} className='h-100 w-100' style={{ backgroundColor: unit?.color }}>
    //         <div className='fp-unit-title textbox-scrollable-darkgray' >
    //             <div className="d-flex" style={{ padding: "2%", alignItems: 'center', justifyContent: 'space-between' }}>
    //                 <Button className="text-decoration-none" classes={{
    //                     label: 'small-text'
    //                 }} style={{ color: 'black', padding: 0 }} onClick={() => {
    //                     if (submit) {
    //                         // setSubmit(false)
    //                         window.location.search.replace("&submit=true", "")
    //                     }
    //                     else if (alternateResponse) {
    //                         window.location.search = window.location.search.replace("&response=true", "")
    //                     }
    //                     // setAlternateResponse(false)
    //                     else if (showFlashpoint) {
    //                         window.location.search = window.location.search.replace("?flashpoint=true", "")
    //                     }
    //                     // setShowFlashpoint(false)

    //                 }}><ChevronLeft /> Back</Button>
    //                 <div className='d-flex' style={{ width: '3.5rem', justifyContent: 'space-around' }}>
    //                     <div className='fp-stepper-dots' style={{ opacity: !alternateResponse && showFlashpoint ? 1 : 0.5 }}></div>
    //                     <div className='fp-stepper-dots' style={{ opacity: alternateResponse ? 1 : 0.5 }}></div>
    //                     <div className='fp-stepper-dots' style={{ opacity: submit ? 1 : 0.5 }}></div>
    //                 </div>
    //                 <Button className="text-decoration-none" classes={{
    //                     label: 'small-text'
    //                 }} style={{ color: 'black', padding: 0 }} onClick={() => setClose(true)}> CLOSE X</Button>
    //                 {close && showCloseDialog()}
    //             </div>
    //             <div className='font-weight-bold' style={{
    //                 display: 'flex',
    //                 padding: '0 2%',
    //                 alignItems: 'center'
    //             }}>
    //                 <img src={FP_Icon} className='fp-icon' alt='fp-icon' />
    //                 <div className='large-text' style={{ lineHeight: '1.2' }}>
    //                     {unit?.title}
    //                 </div>
    //             </div>
    //         </div>
    //         <div style={{ padding: "1%", minHeight: alternateResponse ? '75vh' : '85vh' }}>
    //             {alternateResponse ? <AlternativeResponses unitId={unitId} next={() => {
    //                 // setSubmit(true); 
    //                 window.location.search += "&submit=true"
    //             }} responseData={alternateResponseData} /> : getFlashpointContent(unit?.content[0])}
    //         </div>
    //         {/* <div style={{ padding: "1%", height: alternateResponse ? '75vh' : '85vh' }}>
    //             {alternateResponse ? <AlternativeResponses unitId={unitId} next={() => setSubmit(true)} responseData={alternateResponseData} /> : getFlashpointContent(unit?.content[0])}
    //         </div> */}
    //         {
    //             // scrollBottom && <Fab color="primary" className='fp-unit-floating-button' onClick={scrollToBottom}>
    //             //     <ExpandMore />
    //             // </Fab>
    //             scrollBottom && <img src={ScrollDownButton} className='fp-unit-floating-button' onClick={scrollToBottom} alt='scroll' />

    //         }

    //         {/* <MobileStepper
    //             steps={unit?.content?.length}
    //             position="static"
    //             variant="text"
    //             style={{ height: '10%', boxShadow: '0px -2px 4px -2px #c9c9c9', margin: '0% -2%' }}
    //             activeStep={activeStep}
    //             nextButton={
    //                 <Button size="small" className='fp-view-response-button' onClick={isLast ? () => { setSubmit(true); setShowFlashpoint(false); } : handleNext}>
    //                     {isLast ? "Submit" : <>{activeStep === 0 ? 'Proceed' : 'Next'} &#9654;</>}
    //                 </Button>
    //             }
    //             backButton={
    //                 <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
    //                     Back
    //                 </Button>
    //             }
    //         /> */}

    //         {/* <Button size="small" className='fp-view-response-button' onClick={isLast ? () => { setSubmit(true); setShowFlashpoint(false); } : handleNext}>
    //             {isLast ? "Submit" : <>{activeStep === 0 ? 'Proceed' : 'Next'} &#9654;</>}
    //         </Button>


    //         <Button size="small" className='fp-view-response-button_2' onClick={handleBack} disabled={activeStep === 0}>
    //             Back
    //         </Button> */}

    //     </div>
    // }
    // else
    //     return <div className="w-100 h-100" style={{ backgroundColor: unit?.color, position: 'relative' }}>
    //         <Button className="text-decoration-none fp-fullscreen-img-close-button" classes={{
    //             label: 'small-text'
    //         }} onClick={() => setClose(true)}> CLOSE X</Button>
    //         {close && showCloseDialog()}
    //         <img src={unit?.img_src} className='w-100 h-100' />
    //         <Button className='fp-card-view-fp-button' onClick={() => {
    //             // setShowFlashpoint(true);                 
    //             window.location.search += "?flashpoint=true"
    //         }}>
    //             <span style={{ width: '60%' }}>{unit?.button || "How to convince the customer?"}</span>
    //             <span style={{ fontSize: '22px' }}>&nbsp; &nbsp; &gt;&gt;</span>
    //         </Button>
    //     </div>
}