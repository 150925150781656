import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "./Home.js";
// import EngagementList from "../../components/EngagementList";
import "./redux_actions";
import { SnackbarProvider } from 'notistack'
import Classroom from "./Classroom.js";
import Assessment from "./Assessment.js";
import Rating from "./Rating.js";
import FlashpointsRoute from "./FlashPoints/route";

// import Takeaway from "./Takeaway.js";
import { Redirect } from "react-router";

export default () => {
  let app = useSelector((state) => state.app);
  let auth = useSelector((state) => state.auth);

  let params = {}
  for (let param of window.location.search.replace("?", "").split("&&")) {
    let _param = param.split("=")
    params[_param[0]] = _param[1]
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <Switch>
        {/* <Route exact path="/classroom/live-session"
          render={() => (
            <OTPScreen app={app} auth={auth} />
          )} />
        <Route exact path="/classroom/live-session/:moduleId/Engagements"
          render={() => (
            <EngagementList app={app} auth={auth} />
          )} /> */}
        {/* <Route exact path="/classroom/takeaway/:moduleid" component={Takeaway} /> */}
        <Route exact path="/classroom/rating/:moduleid" component={Rating} />
        {"otp" in params && window.location.pathname != "/classroom" ? <Redirect to={`/classroom?otp=${params["otp"]}`} /> : ""}
        <Route exact path="/classroom/assessment/:moduleid" component={Assessment} />
        <Route exact path="/classroom" component={Classroom} />
        <Route path="/flashpoints" component={FlashpointsRoute} />
        <Route exact path="/" component={Home} />
      </Switch>
      {/* <div className="policy">
        <a href="https://illumine.in/privacy-policy.html" target="_blank">Privacy Policy</a>
        <a href="https://illumine.in/cookie-policy.html" target="_blank">Cookie Policy</a>
        <a href="https://illumine.in/terms-of-use.html" target="_blank">Terms & Conditions</a>
      </div> */}
    </SnackbarProvider>
  );
};