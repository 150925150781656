import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkOTP, getAttendedSessions, getModules, getStudentResult } from "./redux_actions";
import {
    LockOutlined as LockOutlinedIcon,
    Close as CloseIcon,
    // PowerSettingsNew as PowerSettingsNewIcon,
    MoreVert as MoreVertIcon,
    Home as HomeIcon
} from "@material-ui/icons"
import {
    Button,
    CircularProgress,
    Dialog,
    Menu as MuiMenu,
    MenuItem as MuiMenuItem,
    withStyles
} from "@material-ui/core"
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import Takeaway from "./Takeaway";
import AssessmentIcon from "./assets/assessmenticon.svg"
import BlackClass from "./assets/black-class.svg"
import GreenClass from "./assets/green-class.svg"
import GreenPlay from "./assets/green-play.svg"
import GreyClass from "./assets/grey-class.svg"
import TakeAssessment from "./assets/takeassessment-button.svg"
import TipsUpdateIcon from "./assets/tips_and_updates_black_24dp.svg"
import { getStudentByEmail, signOut } from "../auth/redux_actions";
import store from "../../Redux_Store";
import Interaction from "./Interaction"
import MenuBar from "../../components/MenuBar";

function etah(event) {
    switch (event.eventName) {
        default:
            console.log(event.eventData)
    }
}

export default () => {
    let params = {}
    for (let param of window.location.search.replace("?", "").split("&&")) {
        let _param = param.split("=")
        params[_param[0]] = _param[1]
    }




    let myModules = useSelector((state) => state.app.myModules);
    let { app, auth } = useSelector((state) => state);
    const [open, setOpen] = useState((params["otp"] && "otp" in params) || false)
    const [otp, setOtp] = useState(params["otp"] || "")
    const score = app.score
    const { enqueueSnackbar } = useSnackbar();
    const [takeaway, showTakeaway] = useState(false)
    const [error, setError] = useState(null)

    const attendedSession = app.attendedSessions
    const dispatch = useDispatch();

    useEffect(() => {
        if (typeof (auth.user_uid) === 'object') {
            getStudentByEmail(dispatch, auth.user_token, auth.userAccount.email);
        }
        getModules(auth.user_token, auth.user_uid)
        getAttendedSessions(auth.user_token, auth.user_uid)
        getStudentResult({ user_token: auth.user_token, studentid: auth.user_uid })
        setOtp(params["otp"] || "")
    }, [open, auth.user_uid])

    const snackbar = (icon, message, sub_message = null) => {
        return <div style={{ display: 'flex' }}>
            <div className="icon">
                {Number.isInteger(icon) ? <><span>{icon}</span> <span>points</span></> : icon}
            </div>
            <div className="message">
                {message}
                <div style={{ fontWeight: 'normal' }}>{sub_message}</div>
            </div>
        </div>
    }

    const displayScore = (score, maxScore = null, moduleid) => {
        if (!maxScore)
            return <span className="points">
                <span>{score}</span>
                <span>points</span>
            </span>
        else return <Link style={{ display: 'flex', flexDirection: 'column', textDecoration: "none" }} className="pointer points">
            <span style={{ font: 'normal normal 300 28px/22px tablet-gothic', letterSpacing: '-1.4px', color: '#2BB01C' }}>{score}</span>
            <span style={{ font: 'normal normal 300 12px/24px tablet-gothic', color: '#767676', textTransform: 'uppercase' }}>/{maxScore}</span>
            <span>points</span>
            {/* <span className="text fw-bold lime-green">Review <br />Answers</span> */}
        </Link>
    }

    const runningSession = (module, index) => {
        let moduleid = myModules[module] && myModules[module]['moduleId'] && myModules[module]['moduleId']['id']
        let sessionAttend = attendedSession && attendedSession[moduleid]
        let sessionEnd = sessionAttend && attendedSession[moduleid]['end']
        let assessmentDone = score[moduleid] && "assessment" in score[moduleid]

        return <tr key={module}>
            <td className="table-sessionname">
                <span className="table-session-number">Session {index + 1}</span>
                <span>{myModules[module] && myModules[module]['moduleId'] && myModules[module]['moduleId']['description']}</span>
                {sessionEnd ? "" : (sessionEnd == undefined ? "" : <span className="session-progress">SESSION IN PROGRESS</span>)}
            </td>
            <td>{sessionAttend ? displayScore(app.attendedSessions && app.attendedSessions[moduleid] && app.attendedSessions[moduleid]['score']) : <LockOutlinedIcon />} </td>
            <td>
                {sessionEnd ? (
                    assessmentDone ? displayScore(score && score[moduleid] && score[moduleid]['assessment'], 20, moduleid) :
                        <Link to={`/classroom/assessment/${moduleid}`} className="session-button"> <img src={TakeAssessment} /><span className="text">Take Assessment </span> </Link>) : <LockOutlinedIcon />}
            </td>
            <td>
                {sessionEnd && assessmentDone ? <><Button onClick={() => showTakeaway({ moduleid, module })} className="session-button">
                    <img src={GreenPlay} /> <span className="text fw-bold lime-green">read Takeaway </span> </Button></> : <LockOutlinedIcon />
                }
            </td>
        </tr >
    }

    const otpScreen = () => {
        return <Dialog open={open} onClose={() => setOpen(false)} className="otp-model">
            <div style={{ padding: '10px', background: '#F7F7F7 0% 0% no-repeat padding-box', overflowY: "hidden" }}>
                <div><CloseIcon style={{ float: 'right' }} onClick={() => setOpen(false)} /></div>
                <div style={{ marginTop: '50px', font: 'normal normal 600 24px/24px tablet-gothic' }}>Join a Live Session <img src={GreyClass} style={{ float: "right" }} /></div>
                <div className="box">
                    <span className="otp-text">ENTER OTP</span>
                    <OtpInput value={otp} className="otp" onChange={(newOtp) => setOtp(newOtp)} />
                    {error ? <div className="mt-15" style={{ color: "red" }}>{error}</div> : ""}
                    <div style={{ marginTop: '70px', marginBottom: '30px' }}>
                        <Button className="special-button w-100" onClick={() => checkOTP({ user_token: auth.user_token, otp, studentId: auth.user_uid }).then(result => {
                            if (result?.code)
                                setError(result.message)
                            if (result?.otp) {
                                enqueueSnackbar(snackbar(result?.attendence?.score, `You have earned ${result?.attendence?.score} attendance points.`), { className: "custom-snackbar bg-light-green green" });
                                enqueueSnackbar(snackbar(<img src={GreenClass} />, "You have successfully joined the session. You can now focus on the class."), { className: "custom-snackbar bg-light-green green" });
                                setOpen(false)
                                Interaction.init(etah, "Student", auth.userAccount.fullname, auth.userAccount.id, "Coach", result?.otp?.otp)
                            }
                        })} disabled={auth.user_uid == "" || typeof (auth.user_uid) === 'object' ? true : false} > {auth.user_uid == "" || typeof (auth.user_uid) === 'object' ? <><CircularProgress /> Please Wait...</> : "Join"}</Button>
                    </div>
                </div>
            </div>
        </Dialog >
    }

    if (takeaway) {
        return <Takeaway module={takeaway} style={{ margin: '20px 10px', border: '1px dashed #707070', borderRadius: '20px', height: '100%', padding: '20px' }} onClose={() => showTakeaway(false)} />
    }

    return <>
        <div style={{ margin: '34px 13px 20px' }}>
            <MenuBar
                name={auth.userAccount.fullname}
                leftComponent={auth?.userAccount?.fullname ? <>Hi {auth.userAccount.fullname}</> : ""}
            />
            <Button
                // disabled={Object.values(attendedSession).some((session) => session.end == false)} 
                className="special-button w-100 mt-15px" onClick={() => setOpen(true)}>
                <img src={BlackClass} style={{ marginRight: '10px' }} />JOIN A SESSION WITH OTP
            </Button>
        </div>
        <table className="table-session">
            <tbody>
                <tr>
                    <th className="session">Sessions</th>
                    <th className="session-event"><img src={GreyClass} /> ATTENDANCE </th>
                    <th className="session-event"><img src={AssessmentIcon} /> ASSESSMENT</th>
                    <th className="session-event"><img src={TipsUpdateIcon} />TAKEAWAYS</th>
                </tr>
                {Object.keys(myModules || {}).sort().map(runningSession)}
            </tbody>
        </table>
        {otpScreen()}
    </>
}