import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { Link, Redirect } from 'react-router-dom';

export default ({ unitCompleted, buttonClass }) => {
    const [open, setOpen] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const handleClick = () => {
        unitCompleted ? setRedirect(true) : setOpen(true);
    }
    return <>
        <Button className={`text-decoration-none ${buttonClass}`} classes={{
            label: 'small-text'
        }} onClick={handleClick}> CLOSE X</Button>
        {redirect && <Redirect push to='/flashpoints' />}
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle > Are you sure ?</DialogTitle >
            <DialogContent>Do you really want to close the unit? You will not get any points!</DialogContent>
            <DialogActions>
                <Link to={"/flashpoints"} style={{ textDecoration: "none" }}><Button>Yes</Button></Link>
                <Button onClick={() => setOpen(false)}>No</Button>
            </DialogActions>
        </Dialog >
    </>

}


