import { Button, CircularProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getTakeaways } from "./redux_actions"
import Takeaways from "../../takeaways"

export default (props) => {
    const [takeaway, setTakeaway] = useState([])
    const [fetch, setFetch] = useState(false)
    const { auth, app } = useSelector((state) => state)
    const { module } = props
    const attendedSession = app.attendedSessions[module.moduleid]
    const [showTakeaway, setShowTakeaway] = useState(null)
    let uid = auth.user_uid;

    useEffect(() => {
        setFetch(true)
        getTakeaways({ user_token: auth.user_token, sessionId: attendedSession.sessionId }).then((result) => {
            console.log(result)
            if (!result?.code)
                setTakeaway(result['data'])
            setFetch(false)
        })
    }, [])

    return <div style={showTakeaway ? {} : props.style}>
        {fetch ? <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress />
        </div> : takeaway?.length == 0 ? "No Takeaway available for this session" : showTakeaway ? <>
            <Takeaways data={showTakeaway} onBack={props.onClose} uid={uid} /></> :
            takeaway?.length == 1 ? setShowTakeaway(takeaway[0]) :
                <>
                    <div style={{ font: 'normal normal normal 12px/18px tablet-gothic', letterSpacing: '0.48px', textTransform: 'uppercase' }}>TAKEAWAY</div>
                    <div style={{ font: 'normal normal bold 22px/26px tablet-gothic', letterSpacing: '0.22px', marginBottom: '20px' }}>{app.myModules && app.myModules[module.module] && app.myModules[module.module]['moduleId']['description']}</div>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                        {takeaway?.map((t, index) => <Button className="special-button mt-15" onClick={() => setShowTakeaway(t)}>
                            {`Takeaway ${index + 1}`}
                        </Button>)}
                    </div>
                </>
        }
    </div >
}