import store from "../../Redux_Store";
import { signOut } from "../auth/redux_actions"
import Config from "../../config"

const config = {
    // appurl: "http://localhost:5000/v1/student/"
    teacher_url: Config.API_URL,
    // teacher_url: "http://localhost:5001/v1/",
    appurl: Config.API_URL + "student/"
    // http://uat.pedgog.in:3000/v1/student/"
}

export const getModules = async (token, user_uid) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return await fetch(config.appurl + "student/getModuleByStudentId/" + user_uid, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code === 401)
                store.dispatch(signOut())
            else {
                var i;
                for (i = 0; i < (result[0]["AvailableModule"]).length; i++) {
                    getModuleDetail(token, result[0]["AvailableModule"][i]);
                }
            }
        })
        .catch(error => console.log('error', error));
}

export const setCurrentModule = (md) => {
    store.dispatch({ type: "SET_CURRENT_MODULE", payload: md });
}

export const getModuleDetail = (token, moduleId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(config.appurl + "module/getModuleById/" + moduleId, requestOptions)
        .then(response => response.json())
        .then(result => {
            // console.log(result);
            if (result.code === 401)
                store.dispatch(signOut())
            else {
                // console.log(result[0])
                store.dispatch({
                    type: "UPDATE_MODULES",
                    data: result[0]
                })
            }
        })
        .catch(error => console.log('error', error));
}

export const tabIndexChange = (index) => {
    return {
        type: "TAB_INDEX_CHANGE",
        value: index
    }
}

export const updateReadingTracker = ({ uid, resourceId }) => {
    let updates = {};
    let instanceId = Date.now();
    store.dispatch({ type: "UPDATE_TRACKER" });
    // updates[`/users/${uid}/myEngagements/readings/digitalResources/${resourceId}/${instanceId}`] = true;
    // db.ref().update(updates);
    return null;
}

export const checkOTP = async (data) => {
    store.dispatch({ type: "CHECKOTP_ATTEMPT" });
    // return await fetch('http://localhost:5000/v1/student/otp/checkOTP', {
    return await fetch(config.appurl + 'otp/checkOTP', {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "Authorization": "bearer " + data.user_token,
            "accept": "application/json"
        },
        "body": JSON.stringify({
            // moduleId: data.module_Id,
            otp: data.otp,
            studentId: data.studentId
        })
    })
        .then(response => response.json())
        .catch(error => console.error(error))
    // .then(response => {
    //     if (response.code === 401)
    //         return store.dispatch(signOut())
    //     // else if (!response.code)            
    //     return response
    //     // throw Error(response.stack)
    // })
    // .catch(err => {
    //     store.dispatch({ type: 'CHECKOTP_FAILURE', payload: err })
    //     return err
    // });
}
export function manageToolData(data) {
    store.dispatch({ type: "MANAGE_TOOL_DATA", payload: { toolId: data.toolId, content: data.content } });
}

export const reset = () => {
    store.dispatch({ type: "RESET_ENABLE_ENGAMENT" })
}

export const enableEngagement = (engagementId) => {
    store.dispatch({ type: "ENABLE_ENGAGEMENT", payload: engagementId })
}

export const disableEngagement = (engagementId) => {
    store.dispatch({ type: "DISABLE_ENGAGEMENT", payload: engagementId })
}

export const getEngagement = (token, engagementId) => {
    return fetch(config.appurl + `engagement/${engagementId}`, {
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "bearer " + token,
            "accept": "application/json"
        }
    })
        .then(response => response.json())
        .then(response => {
            if (response.code === 401)
                return store.dispatch(signOut())
            return response
        })
        .catch(err => {
            // store.dispatch({ type: 'CHECKOTP_FAILURE', payload: err })
            console.log('error ===', err);
        });
}


export const updateQuizResponse = async ({ userId, quizId, key, value }) => {
    // let ref = db.ref(`/users/${userId}/myEngagements/quizzes/${quizId}`);
    // var updates = {};
    // updates[key] = value;
    // return ref.update(updates);
}

export const getQuiz = async ({ user_token, quizId }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + user_token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return await fetch(config.appurl + "test/" + quizId, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.code == 401)
                return store.dispatch(signOut())
            // else
            // return store.dispatch({ type: "QUIZ_DATA", payload: result })
            return result
        })
        .catch(error => console.log('error', error));
}

export const getQuizForModuleId = async ({ user_token, moduleid }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "moduleid": moduleid
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return await fetch(`${config.appurl}test`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            if (result.code == 401)
                return store.dispatch(signOut())
            // else
            // return store.dispatch({ type: "QUIZ_DATA", payload: result })
            return result
        })
        .catch(error => console.log('error', error));
}

export const logUserResponse = async ({
    token,
    testid,
    studentid,
    answers,
    sessionId
}) => {
    // return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    for (const [questionid, answer] of Object.entries(answers)) {
        console.log(questionid, answer)
        var raw = JSON.stringify({ testid, studentid, questionid, answer, sessionId });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(config.appurl + "testsubmission/", requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.code === 401)
                    store.dispatch(signOut())
                else if (result) {
                    console.log(result)
                }
                else {
                    store.dispatch({ type: "CURRENT_ASSESSMENT_FINISH" });
                }
            })
            .catch(error => console.log('error', error));
    };
    return
    // getTestResult({ token, testid, studentid, sessionId })
};

export const getTestResult = ({ token, testid, studentid, sessionId }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({ testid, studentid, sessionId });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    // return fetch("http://localhost:5000/v1/student/testsubmission/getTestSubmissionInfo", requestOptions)
    return fetch(config.appurl + "testsubmission/getTestSubmissionInfo", requestOptions)
        .then(response => response.json())
        .then((result) => {
            console.log(result)
            if (result.code === 401)
                store.dispatch(signOut())
            return result
        })
        .catch(error => console.log('error', error));
}

export const getTestSubmission = ({ token, testid, studentid }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({ testid, studentid, sessionId: "5f44c18a79e9373590878b52" });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    return fetch(config.appurl + "testsubmission/getTestSubmission", requestOptions)
        .then(response => response.json())
        .then((result) => {
            console.log(result)
            if (result.code === 401)
                store.dispatch(signOut())
            return result
        })
        .catch(error => console.log('error', error));
}

export const submitRating = async ({ token, sessionId, rating, feedback, userId }) => {
    console.log(rating)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        sessionId,
        rating,
        userId
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    // return await fetch(`http://localhost:5001/v1/session/rating`, requestOptions)
    fetch(`${config.teacher_url}session/rating`, requestOptions)
        .then(response => response.json())
}

export async function getTakeaways(data) {
    // data.sessionId = "6125c2f5ed135800306bd472"
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${data.user_token}`);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return await fetch(`${config.teacher_url}takeaway/${data.sessionId}`, requestOptions)
        // return await fetch(`http://localhost:8000/v1/takeaway/${data.sessionId}`, requestOptions)
        .then((response) => response.json())
}

export async function getAttendedSessions(user_token, studentid) {
    // return await callAPI(user_token, "GET", `${config.teacher_url}session/attendedSession/${studentid}`)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user_token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    // return await fetch(`http://localhost:5001/v1/session/attendedSession/${studentid}`, requestOptions)
    return await fetch(`${config.teacher_url}session/attendedSession/${studentid}`, requestOptions)
        .then(response => response.json())
        .then((result) => {
            if ("code" in result);
            else
                store.dispatch({ type: 'ATTENDED_SESSION', payload: result })
        }).catch(error => console.error(error))
}

export const getStudentResult = async ({ user_token, studentid }) => {
    // return await callAPI(user_token, "GET", `${config.appurl}testsubmission/getStudentScore/${studentid}`)

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user_token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    // await fetch(`http://localhost:5000/v1/student/testsubmission/getStudentScore/${studentid}`, requestOptions)
    return await fetch(`${config.appurl}testsubmission/getStudentScore/${studentid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            if ("code" in result);
            else
                store.dispatch({ type: 'STUDENT_RESULT', payload: result })
            return result
        })
        .catch(error => console.log('error', error));
}

export const isSessionRated = async ({ user_token, sessionId, userId }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        sessionId,
        userId
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    // return fetch("http://localhost:5001/v1/session/rated", requestOptions)
    return fetch(`${config.teacher_url}session/rated`, requestOptions)
}

export const sendTakeawayResponse = async ({ user_token, takeawayId, studentId, response }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        takeawayId,
        studentId,
        response
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    // fetch("http://localhost:8000/v1/takeaway/student/response", requestOptions)
    fetch(`${config.teacher_url}takeaway/student/response`, requestOptions)
        .then(response => response.json())
        .then((result) => console.log(result))
}

export const getTakeawayResponse = async ({ user_token, takeawayId, studentId }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        takeawayId,
        studentId,
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    // return fetch("http://localhost:8000/v1/takeaway/student/response", requestOptions)
    return fetch(`${config.teacher_url}takeaway/student/response`, requestOptions)
        .then(response => response.json())
    // .then((result) => console.log(result))
}

export const getReview = async ({ user_token, studentId, moduleId, successCallBack }) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + user_token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        studentId,
        moduleId
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    // fetch("http://localhost:5000/v1/student/testsubmission/review", requestOptions)
    fetch(`${config.appurl}testsubmission/review`, requestOptions)
        .then(response => response.json())
        .then((result) => successCallBack(result))
}

export const flashPointsSelectedUnit = (unitId) => {
    store.dispatch({ type: "FLASH_POINTS_SELECTED_UNIT", payload: unitId })
}

export const getFlashpointUnit = async (token, unitId, studentId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${config.appurl}flashpoint/${unitId}?studentId=${studentId}`, requestOptions)
        .then(response => response.json())
}


export const callAPI = async (token, method, url, body) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method,
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
    };

    console.log(body);

    return await fetch(url, requestOptions).then(response => response.json())
}

export const getFlashpointUnits = async (token, userId) => {
    return await callAPI(token, "GET", `${config.appurl}flashpoint/getFlashpointForUser/${userId}`)
}

export const logEvent = async (token, studentId, eventName, eventData) => {
    return await callAPI(token, "POST", `${config.appurl}logevent/`, { studentId, eventName, eventData })
}

export const saveFeedback = async (token, flashpointId, studentId, feedback) => {
    return await callAPI(token, "POST", `${config.appurl}flashpoint/feedback`, { flashpointId, studentId, feedback })
}

export const voteResponse = async (token, flashpointId, responseId, studentId, vote) => {
    return await callAPI(token, "POST", `${config.appurl}flashpoint/response/vote`, { flashpointId, responseId, studentId, vote })
}

export const getBestPractice = async (token, unitId, filter, studentId, pageNum) => {
    return await callAPI(token, "GET", `${config.appurl}flashpoint/bestPractice/${unitId}?location=${filter}&studentId=${studentId}&page=${pageNum}`)
}

export const getFlashpointDashboard = async (token, userId) => {
    return await callAPI(token, "GET", `${config.appurl}flashpoint/dashboard/${userId}`)
}

export const getLeaderboard = async (token, userId) => {
    return await callAPI(token, "GET", `${config.appurl}flashpoint/leaderboard/${userId}`)
}

export const completeFlashpoint = async (token, unitId, studentId, status, isBookmark) => {
    return await callAPI(token, "POST", `${config.appurl}flashpoint/complete`, { unitId, studentId, status, isBookmark })
}

export const addFlashpointData = async (token, flashpointId, studentId, data) => {
    const flashpointData = data
    return await callAPI(token, "POST", `${config.appurl}flashpoint/addFlashpointData`, { flashpointId, studentId, flashpointData })
}

export const addResponse = async (token, flashpointId, userId, textResponse, voiceResponse) => {
    return await callAPI(token, "POST", `${config.appurl}flashpoint/addResponse`, { flashpointId, userId, textResponse, voiceResponse, voiceResponse })
}

export const getTags = async (token) => {
    return await callAPI(token, "GET", `${config.appurl}flashpoint/tags`)
}

export const searchFlashpointByTag = async (studentId, token, tag, s) => {
    if (tag)
        return await callAPI(token, "GET", `${config.appurl}flashpoint/search?studentId=${studentId}&tag=${tag}`)
    return await callAPI(token, "GET", `${config.appurl}flashpoint/search?studentId=${studentId}&s=${s}`)
}

export const getScore = async (token, studentId) => {
    return await callAPI(token, "GET", `${config.appurl}flashpoint/score/${studentId}`)
}

export const getDashboard = async (token, studentId) => {
    return await callAPI(token, "GET", `${config.appurl}student/${studentId}/dashboard`)
}

export const firstTimeUser = async (token, userId) => {
    return await callAPI(token, "GET", `${config.appurl}flashpoint/firstUser/${userId}`)
}

export const setFirstTimeUse = async (token, userId, body) => {
    return await callAPI(token, "POST", `${config.appurl}flashpoint/firstUser/${userId}`, body)
}

export const deleteFpResponse = async (token, responseId) => {
    return await callAPI(token, "DELETE", `${config.appurl}flashpoint/deleteResponse/${responseId}`)
}

export const getFlashpointData = async (token, flashpointId, studentId) => {
    return await callAPI(token, "POST", `${config.appurl}flashpoint/getFlashpointData`, { flashpointId, studentId })
}